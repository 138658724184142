(function (App, $, window, document) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Menu');

  /**
   * Menu constructor
   *
   * @param {Object} options
   */
  function Menu(options) {
    if (!(this instanceof Menu)) {
      return new Menu(options);
    }

    this.body = document.body;
    this.burger = document.querySelector('.header__burger');
    this.btns = document.querySelectorAll('.menu-main__has-children');
    this.items = document.querySelectorAll('[data-img]');
    this.picture = document.querySelector('.menu__img');
    const imgDefaultElement = document.querySelector('[data-default]');
    this.imgDefault = imgDefaultElement ? imgDefaultElement.dataset.default : null;
    this.mobileBtn = document.querySelectorAll('.menu__mobile-btn');
    this.itemClicked = null;
    // @TODO Remove jQuery selectors
    this.$sublists = $('.menu-main__sublist');
    this.$mainlist = $('.menu-main__list');

    this.mouseoverHandler = this.mouseoverHandler.bind(this);
    this.mouseleaveHandler = this.mouseleaveHandler.bind(this);
    this.handleMenu = this.handleMenu.bind(this);

    return this;
  }

  /**
   * Init menu
   */
  Menu.prototype.init = function () {
    log('::init');
    const self = this;

    // mouseover
    if (this.items.length > 0) {
      for (let i = 0; i < this.items.length; i += 1) {
        this.items[i].addEventListener('mouseover', self.mouseoverHandler);
        this.items[i].addEventListener('mouseleave', self.mouseleaveHandler);
      }
    }

    if (this.mobileBtn.length > 0) {
      for (let i = 0; i < this.mobileBtn.length; i += 1) {
        this.mobileBtn[i].addEventListener('click', self.handleSubmenu);
      }
    }

    this.burger.addEventListener('click', self.handleMenu);
  };

  /**
   * Handle open/close submenu
   * @param  {object} currentTarget
   * @return {void}
   */
  Menu.prototype.handleSubmenu = function (e) {
    const parent = e.currentTarget.parentNode;

    if (parent === this.itemClicked) {
      this.itemClicked.classList.remove('menu__col--clicked');
      this.itemClicked = null;
    } else {
      parent.classList.add('menu__col--clicked');
      this.itemClicked = parent;
    }
  };

  /**
   * Handle menu
   */
  Menu.prototype.handleMenu = function () {
    log('::handle');

    if (this.body.classList.contains('open-menu')) {
      this.close();
    } else {
      this.open();
    }
  };

  /**
   * Open menu
   */
  Menu.prototype.open = function () {
    log('::open');

    this.burger.setAttribute('aria-pressed', true);
    this.body.classList.add('open-menu');
  };

  /**
   * Close menu
   */
  Menu.prototype.close = function () {
    log('::close');
    this.burger.setAttribute('aria-pressed', false);
    this.body.classList.remove('open-menu');
  };

  /**
   * Mouveover
   *
   * @param {Object} Event
   */
  Menu.prototype.mouseoverHandler = function (event) {
    const item = event.target;
    const itemPicture = item.dataset.img === '' ? this.imgDefault : item.dataset.img;

    const pictureContainer = document.querySelector(
      `[data-picture="${item.dataset.pictureContainer}"]`
    );

    if (!itemPicture || pictureContainer === null) {
      return;
    }

    this.picture = pictureContainer;
    this.picture.setAttribute('src', itemPicture);
  };

  /**
   * Mouseleave
   */
  Menu.prototype.mouseleaveHandler = function () {
    if (!this.imgDefault) {
      return;
    }

    this.picture.setAttribute('src', this.imgDefault);
  };

  // Init menu
  window.addEventListener('load', function () {
    const menu = new Menu();
    menu.init();
    App.components.menu = menu;
  });
})(App, jQuery, window, document);
